Core.StickyHeader = Garnish.Base.extend(
{
    $header: $('.bc-header-sticky'),
    $headerTop: $('.bc-header-top'),
    $headerWrapper: $('.bc-header-wrapper'),
    headerHeight: 0,
    headerTopHeight: 0,

    init: function(settings)
    {
        Core.debug && console.log('Core.StickyHeader.init() invoked');

        if ($('#ccm-toolbar')[0])
        {
            return;
        }

        if (Garnish.$bod.hasClass('bc-mobile-header-navigation-open'))
        {
            return;
        }

        if (! this.$header[0])
        {
            return;
        }

        this.setSettings(settings, Core.StickyHeader.defaults);

        this.headerHeight    = this.$header[0] ? this.$header.outerHeight() : 0;
        this.headerTopHeight = this.$headerTop[0] ? this.$headerTop.outerHeight() : 0;

        this.addListener(Garnish.$scrollContainer, 'scroll', 'onScroll');
        this.addListener(Garnish.$scrollContainer, 'resize', 'updateHeader');


        if (0 === this.getScrollPoint())
        {
            //this.stickyHeaderOn();
        }
    },

    onScroll: function(e)
    {
        if (Garnish.$bod.hasClass('bc-mobile-header-navigation-open'))
        {
            return;
        }

        var $this = $(e.currentTarget),
            scrollTop = $this.scrollTop(),
            width = $this.width(),
            scrollPoint = (null !== this.getScrollPoint()) ? this.getScrollPoint() : (this.headerTopHeight + this.headerHeight);

        if (scrollTop > scrollPoint && width > 0)
        {
            this.stickyHeaderOn();
        }
        else
        {
            Garnish.$bod.removeClass(this.settings.cssStickyHeaderOn);
            this.$header.removeClass('x-animated x-menu-fadeInDown');
            this.$headerWrapper.css('paddingBottom', 0);
        }
    },

    stickyHeaderOn: function()
    {
        Garnish.$bod.addClass(this.settings.cssStickyHeaderOn);

        if (this.settings.animate)
        {
            this.$header.addClass('x-animated x-menu-fadeInDown');
        }

        this.$headerWrapper.css('paddingBottom', this.headerHeight + 'px');
    },

    updateHeader: function(e)
    {
        var $this = $(e.currentTarget), scrollPoint;

        if (Garnish.$bod.hasClass('bc-mobile-header-navigation-open'))
        {
            return;
        }

        if ($this.width() < 768)
        {
            return;
        }

        scrollPoint = (null !== this.getScrollPoint()) ? this.getScrollPoint() : (this.headerTopHeight + this.headerHeight);
        scrollPoint = scrollPoint - 5;

        this.headerHeight = this.$header[0] ? this.$header.outerHeight() : 0;
        this.headerTopHeight = this.$headerTop[0] ? this.$headerTop.outerHeight() : 0;

        if ($this.scrollTop() < scrollPoint)
        {
            this.headerHeight = this.$header[0] ? this.$header.outerHeight() : 0;
            this.headerTopHeight = this.$headerTop[0] ? this.$headerTop.outerHeight() : 0;
        }
    },

    getScrollPoint: function ()
    {
        var breakPoints = Core.breakPoints,
            scrollPoints = this.settings.scrollPoints,
            currentBreakPoint = Core.currentBreakPoint,
            scrollPoint,
            value = 0;

        if (null === scrollPoints)
        {
            return null;
        }

        for (var breakPoint in breakPoints)
        {
            if (! breakPoints.hasOwnProperty(breakPoint)) continue;

            if (scrollPoints.hasOwnProperty(breakPoint))
            {
                value = scrollPoints[breakPoint];
            }

            scrollPoint = value;

            if (breakPoint === currentBreakPoint)
            {
                break;
            }
        }

        return scrollPoint;
    }

},
{
    defaults: {
        'scrollPoints': null,
        'animate': false,
        'cssStickyHeaderOn': 'bc-header-sticky-on'
    }
});

Core.BasicSlider = Garnish.Base.extend(
{
    Selector: {
        SLIDER: '.bc-slider',
        SLIDE: '.owl-item',
        CAPTION: '.owl-caption-holder'
    },

    $slider: null,

    init: function(settings)
    {
        Core.debug && console.log('Core.BasicSlider.init() invoked');

        this.$slider = $(this.Selector.SLIDER);

        if (! this.$slider[0])
        {
            return;
        }

        var self = this;

        this.setSettings($.extend(settings, this.$slider.data('bc-slider-options')), Core.BasicSlider.defaults);

        //console.log(this.settings);

        // Bind caption animation to owl carousel.
        // The initialization event must be defined before starting owl carousel.
        this.$slider.on('initialized.owl.carousel', function(event) {
            self.addAnim(event);
        });

        this.$slider.owlCarousel(this.settings);

        this.$slider.on('translate.owl.carousel', function(event) {
            self.addAnim(event);
        });
    },

    addAnim: function(event)
    {
        // No animation if slider doesn't have slides
        if (event.item.count <= 0) return;

        // Get the transition event t
        var transitionEvent = Core.whichTransitionEvent();

        var items = this.$slider.find(this.Selector.SLIDE),
            $currentItem = $(items[event.item.index]),
            $captionHolder = $currentItem.find(this.Selector.CAPTION),
            animation = 'animated ' + $captionHolder.data('animation'),
            animationDelay = $captionHolder.data('animation-delay');

        $captionHolder.find('*').css('visibility', 'hidden');
        $captionHolder.find('*').each(function(idx)
        {
            var $self = $(this);
            setTimeout(function()
            {
                $self.css('visibility', 'visible');
                $self.addClass(animation).one(transitionEvent, function()
                {
                    $(this).removeClass(animation)
                });
            }, (idx + 1) * animationDelay);
        });
    }

},
{
    defaults: {
        //animateOut: 'fadeOut',
        //animateIn: 'fadeIn',
        items: 1,
        stagePadding: 0,
        smartSpeed: 450,
        margin: 10,
        nav: true,
        dots: true,
        loop: true,
        navRewind: false,
        autoplay: false,
        autoplayTimeout: 7000,
        autoplayHoverPause: true,
        infinite: true,
        responsiveClass: true,
        mouseDrag: false,

        // basic slider settings
        duration: 7000,
        progressBar: true
    }
});

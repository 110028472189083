Core.ProductFilter = Garnish.Base.extend(
{
    $toggler: null,

    Selector: {
        'TOGGLER'   : '.bc-product-filter-toggler, .bc-product-filter-title',
        'CONTAINER' : '.bc-product-filter-block',
        'COLLAPSED' : '.bc-product-filter-block-collapsed',
        'ACTIVE'    : '.bc-product-filter-active'
    },

    ClassName: {
        'COLLAPSE'  : 'bc-product-filter-block-collapsed'
    },

    init: function(settings)
    {
        var self = this;

        Core.debug && console.log('Core.MobileMenu.init() invoked');

        this.setSettings(settings, Core.MobileMenu.defaults);

        this.$toggler = $(this.Selector.TOGGLER);

        this.addListener(this.$toggler, 'click', 'toggleFilter');
        this.addListener(Garnish.$win, 'resize', 'updateCollapsedState');

        this.updateCollapsedState();
    },

    updateCollapsedState: function()
    {
        var self = this;

        if (Garnish.$win.width() < 768)
        {
            $(this.Selector.CONTAINER).not(this.Selector.COLLAPSED).each(function()
            {
                $(this).addClass(self.ClassName.COLLAPSE);
            });

            return;
        }

        $(this.Selector.COLLAPSED).each(function()
        {
            var $activeFilter = $(this).find(self.Selector.ACTIVE);

            if ($activeFilter[0])
            {
                $activeFilter.closest(self.Selector.CONTAINER).removeClass(self.ClassName.COLLAPSE);
            }
        });
    },

    toggleFilter: function(e)
    {
        var $toggler = $(e.target),
            $container = $toggler.closest(this.Selector.CONTAINER);

        $container.toggleClass(this.ClassName.COLLAPSE);
    }
},
{
    defaults: {}
});

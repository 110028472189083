Core.MobileMenu = Garnish.Base.extend(
{
    $toggler: $('.bc-navbar-toggler'),
    $navbar: $('.nav-main-collapse'),

    init: function(settings)
    {
        var self = this;

        Core.debug && console.log('Core.MobileMenu.init() invoked');

        this.setSettings(settings, Core.MobileMenu.defaults);

        this.$toggler.on('click', function(e)
        {
            e.preventDefault();

            if (self.$navbar.hasClass('show'))
            {
                self.$navbar.removeClass('show');
                $(this).removeClass('show');
            }
            else
            {
                self.$navbar.addClass('show');
                $(this).addClass('show');
            }
        });
    }
},
{
    defaults: {
    }
});
